import React, { useState } from "react";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";
import _get from "lodash/get";
import _map from "lodash/map";
import { COLORS } from "../../../../constants/colors";
import Icon from 'react-native-vector-icons/FontAwesome';


const BookingPrice = ({ price }) => {
  const [showDetails, setShowDetails] = useState(false);

  const toggleShowDetails = () => setShowDetails(!showDetails);

  return (
    <TouchableOpacity onPress={toggleShowDetails} style={styles.container}>
      {!showDetails ? (
        <View style={styles.row}>
          <Text style={styles.boldText}>Total Payable Amount</Text>
          <Text style={styles.boldText}>{`₹ ${_get(price, "totalPrice")}`}
            <Icon name="chevron-down" size={16} color="#000" style={{ marginLeft: 6 }} />
          </Text>
        </View>
      ) : (
        _map(_get(price, "breakup"), ({ label, value }, index) => {
          let textStyle = styles.text;
          if (label === "Total Payable Amount") textStyle = [styles.text, styles.boldText];

          return (
            <View key={index} style={styles.row}>
              <Text style={textStyle}>{label}</Text>
              <Text style={textStyle}> {`₹ ${value}`}{" "}
                {label === "Space Usage Fee" ? (
                  <Icon name="chevron-up" size={16} color="#000" />
                ) : (
                  <View style={styles.iconSpacer} />
                )}
              </Text>
            </View>
          );
        })
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 8,
    padding: 8,
    backgroundColor: "#F8F8F9",
    borderRadius: 4,
    marginTop: 20,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 4,
    backgroundColor: COLORS.HALF_WHITE,
    borderRadius: 4,
  },
  text: {
    marginBottom: 0,
    fontSize: 16,
    color: "#000",
  },
  boldText: {
    fontWeight: "bold",
  },
  iconSpacer: {
    width: 10,
  },
});

export default BookingPrice;
