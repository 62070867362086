import { createSlice } from '@reduxjs/toolkit';
import _set from 'lodash/set';
import _get from 'lodash/get';
import { logoutUserAction } from '../commonActions';

const INITIAL_STATE = {
  firstOpen: true,
  user: null,
  favorites: {},
  organization: null,
  reloadFavorites: false,
  calendarId: '',
  whatsNewVisisted: {},
};

const userSlice = createSlice({
  name: 'user',
  initialState: INITIAL_STATE,
  reducers: {
    setFirstOpen(state) {
      _set(state, 'firstOpen', false);
    },
    setUser(state, { payload }) {
      //Support B2C
      if (!payload.organizationId) {
        payload["organizationId"] = "gofloaters_in";
      }
      _set(state, 'user', payload);
    },
    setFavorites(state, { payload }) {
      _set(state, 'favorites', payload);
    },
    setReloadFavorites(state, { payload }) {
      _set(state, 'reloadFavorites', payload);
    },
    setCalendarId(state, { payload }) {
      // console.log({ payload });
      _set(state, 'calendarId', payload);
    },
    setUserSetInfo(state, { payload }) {
      const currentData = _get(state, 'user');
      const data = payload;
      if (!_get(data, 'favourites')) {
        _set(data, 'favourites', []);
      }
      _set(state, 'user', { ...currentData, ...data });
    },
    setOrganizationData(state, { payload }) {
      // console.log({ organizationReducer: payload });
      _set(state, 'organization', payload);
    },
    updateWhatsNew(state, { payload }) {
      const { version } = payload;
      const whatsNew = _get(state, 'whatsNewVisisted');
      _set(state, 'whatsNewVisisted', {
        ...whatsNew,
        [version]: true,
      });
    },
  },
  extraReducers: builder => {
    builder.addCase(
      logoutUserAction,
      (state, action) =>
        // console.log({ state, action, INITIAL_STATE });
        INITIAL_STATE
    );
  },
});

export const {
  setFirstOpen,
  setUser,
  setFavorites,
  setUserSetInfo,
  setOrganizationData,
  setReloadFavorites,
  setCalendarId,
  updateWhatsNew,
} = userSlice.actions;
export default userSlice.reducer;
