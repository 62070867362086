import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Image, View, StyleSheet, Dimensions, Platform,Linking } from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import * as Google from 'expo-auth-session/providers/google';
import { Ionicons } from '@expo/vector-icons';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _size from 'lodash/size';

import { useToast } from 'react-native-toast-notifications';
import { connect } from 'react-redux';
import { IMAGES } from '../../assets/images';
import Button from '../../components/Button';
import { authenticateWithApple, authenticateWithGoogle, isUserExistinDB } from '../../utils/firebaseUtils';
import { createUser, getUserAppleDetails, saveAppleDetailsTemp } from '../../api/common';
import SignupDetails from '../signupDetails/SignupDetails';
import { getCreateUserPayload, getUserLocation } from '../../utils/common';
import Carousel from '../../components/carousel/CarouselFlatlist';
import { COLORS } from '../../constants/colors';
import Text from '../../components/Text';
import LinkButton from '../../components/LinkButton';
import { ROUTES } from '../../constants/routes';
import { onApplePress } from '../../utils/appleLogin';
import { SPACING,MAXDEVICEWIDTH } from '../../constants/common';

const { width } = Dimensions.get('window');
const IMAGE_WIDTH = (width > MAXDEVICEWIDTH ? MAXDEVICEWIDTH:width);

const CAROUSEL_IMAGES = [
  { image: IMAGES.ONBOARDING_1 },
  { image: IMAGES.ONBOARDING_2 },
  { image: IMAGES.ONBOARDING_3 },
];

WebBrowser.maybeCompleteAuthSession();

function Login({ metadata, navigation }) {
  const [email, setEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [imageHeight, setImageHeight] = useState(0);
  const [currentView, setCurrentView] = useState('LOGIN');
  const [signupfirebaseDetails, setSignupFirebaseDetails] = useState(null);
  const usersDataRef = useRef();
  const flatlistRef = useRef(null);
  const timerRef = useRef(null);
  const currentIndex = useRef(0);
  const toast = useToast();
  const [_, response, promptAsync] = Google.useAuthRequest({
    androidClientId: '187167465849-eaaqvciq8uceqfs389040tong94i6qte.apps.googleusercontent.com',
    iosClientId: '187167465849-ebc5idoiud0mlcbk2heclv0i11hh1joi.apps.googleusercontent.com',
    webClientId: '187167465849-uonvt7aghkl891soirk9209mev8d3bkn.apps.googleusercontent.com',
    expoClientId: '187167465849-uonvt7aghkl891soirk9209mev8d3bkn.apps.googleusercontent.com',
    // redirectUri: makeRedirectUri({
    //   scheme: 'goFloaters',
    // }),
  });

  useEffect(() => {
    timerRef.current = setInterval(() => {
      const nextIndex = currentIndex.current + 1 ?? 0;
      flatlistRef?.current?.scrollToOffset({
        animated: true,
        offset: width * nextIndex,
      });
      if (nextIndex === _size(CAROUSEL_IMAGES) - 1) currentIndex.current = -1;
      else currentIndex.current += 1;
    }, 3000);
    return () => {
      clearInterval(timerRef.current);
    };
  }, [flatlistRef.current]);

  useEffect(() => {
    // console.log({ response });
    if (response?.type === 'success') {
      // setToken(response.authentication.accessToken);
      getUserInfo(response.authentication.accessToken);
    }
  }, [response]);

  const getUserInfo = async userToken => {
    try {
      const userResponse = await fetch('https://www.googleapis.com/userinfo/v2/me', {
        headers: { Authorization: `Bearer ${userToken}` },
      });

      const authDetails = await authenticateWithGoogle(userToken);
      const usersData = await userResponse.json();
      usersDataRef.current = usersData;
      // console.log({ usersData });
      const userEmail = _get(usersData, 'email');
      const userExists = await isUserExistinDB(userEmail);
      if (!userExists) {
        setSignupFirebaseDetails(authDetails);
        setEmail(userEmail);
        setCurrentView('SIGN_UP');
      }
    } catch (error) {
      console.log({ error });
    }
  };

  // const onAppleButtonPressonApple = async () => {
  //   try {
  //     // 1). start a apple sign-in request
  //     const appleAuthRequestResponse = await appleAuth.performRequest({
  //       requestedOperation: appleAuth.Operation.LOGIN,
  //       requestedScopes: [appleAuth.Scope.EMAIL, appleAuth.Scope.FULL_NAME],
  //     });

  //     // 2). if the request was successful, extract the token and nonce
  //     const { identityToken, nonce } = appleAuthRequestResponse;
  //     console.log({ identityToken, nonce });
  //     // can be null in some scenarios
  //     if (identityToken) {
  //       // 3). create a Firebase `AppleAuthProvider` credential
  //       // const appleCredential = firebase.auth.AppleAuthProvider.credential(identityToken, nonce);
  //       // // 4). use the created `AppleAuthProvider` credential to start a Firebase auth request,
  //       // //     in this example `signInWithCredential` is used, but you could also call `linkWithCredential`
  //       // //     to link the account to an existing user
  //       // const userCredential = await firebase.auth().signInWithCredential(appleCredential);
  //       // // user is now signed in, any Firebase `onAuthStateChanged` listeners you have will trigger
  //       // console.warn(`Firebase authenticated via Apple, UID: ${userCredential.user.uid}`);
  //       const user = await authenticateWithApple(identityToken, nonce);
  //       console.log({ userapple: user });
  //       const userExists = await isUserExistinDB(_get(user, 'email'));
  //       console.log({ userExists });
  //       if (!userExists) {
  //         setSignupFirebaseDetails(user);
  //         setEmail(_get(user, 'email'));
  //         setCurrentView('SIGN_UP');
  //       }
  //     } else {
  //       // handle this - retry?
  //     }
  //   } catch (error) {
  //     console.log({ error });
  //   }
  // };

  // const onAppleButtonPressonAndroid = async () => {
  //   const rawNonce = uuid();
  //   const state = uuid();

  //   // Configure the request
  //   appleAuthAndroid.configure({
  //     // The Service ID you registered with Apple
  //     clientId: 'com.gofloaters.rn.app.android',

  //     // Return URL added to your Apple dev console. We intercept this redirect, but it must still match
  //     // the URL you provided to Apple. It can be an empty route on your backend as it's never called.
  //     redirectUri: 'https://gofloaters.firebaseapp.com/__/auth/handler',

  //     // The type of response requested - code, id_token, or both.
  //     responseType: appleAuthAndroid.ResponseType.ALL,

  //     // The amount of user information requested from Apple.
  //     scope: appleAuthAndroid.Scope.ALL,

  //     // Random nonce value that will be SHA256 hashed before sending to Apple.
  //     nonce: rawNonce,

  //     // Unique state value used to prevent CSRF attacks. A UUID will be generated if nothing is provided.
  //     state,
  //   });

  //   // Open the browser window for user sign in
  //   const responseAndroid = await appleAuthAndroid.signIn();
  //   const { id_token: idToken, nonce } = responseAndroid;
  //   const { email: appleEmail } = jwtDecode(idToken);
  //   console.log({ responseAndroid, appleEmail });
  //   if (idToken) {
  //     // 3). create a Firebase `AppleAuthProvider` credential
  //     // const appleCredential = firebase.auth.AppleAuthProvider.credential(identityToken, nonce);
  //     // // 4). use the created `AppleAuthProvider` credential to start a Firebase auth request,
  //     // //     in this example `signInWithCredential` is used, but you could also call `linkWithCredential`
  //     // //     to link the account to an existing user
  //     // const userCredential = await firebase.auth().signInWithCredential(appleCredential);
  //     // // user is now signed in, any Firebase `onAuthStateChanged` listeners you have will trigger
  //     // console.warn(`Firebase authenticated via Apple, UID: ${userCredential.user.uid}`);
  //     const user = await authenticateWithApple(idToken, nonce);
  //     console.log({ userapple: user });
  //     const userExists = await isUserExistinDB(_get(user, 'email'));
  //     console.log({ userExists, });
  //     if (!userExists) {
  //       setSignupFirebaseDetails(user);
  //       setEmail(appleEmail);
  //       setCurrentView('SIGN_UP');
  //     }
  //   } else {
  //     // handle this - retry?
  //   }
  // };

  const onSignupPress = useCallback(
    async ({ displayName, phoneNumber, email, companyName, profession, referral, organizationId, userCity }) => {
      // let authDetails = null;
      // if (signupMethod === SIGN_UP_METHODS.GOOGLE) {
      //   authDetails = await authenticateWithGoogle(token);
      // } else {
      //   const { identityToken, nonce } = appleCreds;
      //   authDetails = await authenticateWithApple(identityToken, nonce);
      //   console.log({ authDetails, identityToken, nonce });
      // }
      // console.log({ signupfirebaseDetails });
      const payload = getCreateUserPayload(signupfirebaseDetails, {
        displayName,
        phoneNumber,
        companyName,
        signupReferralCode: referral,
        profession,
        userCity,
        location: getUserLocation(userCity, metadata),
        email,
        organizationId,
      });
      // console.log({ payload });
      const userResponse = await createUser(payload);

      if (!_isEmpty(userResponse)) {
        toast.show('User signup successful.');
      } else {
        toast.show('Error updating user details. please try again');
      }
    },
    [email]
  );

  const navigateToSignInScreen = () => {
    navigation.navigate(ROUTES.SIGNIN_WITH_EMAIL.name);
  };

  const renderImage = () => (
    <View style={{ flex: 1, position: 'relative' }}>
      <Carousel
        ref={flatlistRef}
        data={CAROUSEL_IMAGES}
        paginationWidth={IMAGE_WIDTH}
        containerStyle={{ flex: 1 }}
        renderItem={({ item }) => (
          <Image style={{ width:IMAGE_WIDTH, height: imageHeight }} source={item.image} resizeMode="cover" />
        )}
        keyExtractor={(_, index) => index}
        onLayout={({ height }) => setImageHeight(height)}
        hidePagination
      />
      <View style={{ position: 'absolute', bottom: -2 }}>
        <Image source={IMAGES.LOGIN_CURVE} style={{ width:IMAGE_WIDTH, height: 145 }} resizeMode="cover" />
      </View>
    </View>
  );

  const onAppleButtonPress = async () => {
    if (Platform.OS !== 'web') {
      const { idToken, nonce, fullName } = await onApplePress();
      const user = await authenticateWithApple(idToken, nonce);
      const userExists = await isUserExistinDB(_get(user, 'email'));
      if (!userExists) {
        if (fullName) {
          setUserName(fullName);
          await saveAppleDetailsTemp(_get(user, 'uid'), fullName, _get(user, 'email'));
        } else {
          const getTempAppleUserDetails = await getUserAppleDetails(_get(user, 'uid'));
          setUserName(_get(getTempAppleUserDetails, 'fullName') || '');
        }
        setSignupFirebaseDetails(user);
        setEmail(_get(user, 'email'));
        setCurrentView('SIGN_UP');
      }
    } else {
      const user = await onApplePress();
      const userExists = await isUserExistinDB(_get(user, 'email'));
      // console.log({ userExists });
      if (!userExists || true) {
        setEmail(_get(user, 'email'));
        setCurrentView('SIGN_UP');
        setSignupFirebaseDetails(user);
      }
    }
  };

  const renderLoginActionButtons = () => (
    <View style={styles.loginButtonsContainer}>
      <LinkButton
        to={{ screen: ROUTES.SIGNIN_WITH_EMAIL.name }}
        variant="small"
        style={styles.buttonStyle}
        iconButtonStyle={styles.iconButtonStyle}
        leftIcon={<Ionicons name="mail" size={16} color={COLORS.TEXT_COLOR_PRIMARY} />}
        textProps={{ style: { color: COLORS.TEXT_COLOR_PRIMARY }, size: 3 }}>
        Sign up with Email
      </LinkButton>
      <Button
        onPress={() => promptAsync()}
        variant="small"
        style={styles.buttonStyle}
        iconButtonStyle={styles.iconButtonStyle}
        leftIcon={<Ionicons name="logo-google" size={16} color={COLORS.TEXT_COLOR_PRIMARY} />}
        textProps={{ style: { color: COLORS.TEXT_COLOR_PRIMARY }, size: 3 }}>
        Sign up with Gmail
      </Button>
      <Button
        onPress={onAppleButtonPress}
        variant="small"
        style={styles.buttonStyle}
        iconButtonStyle={styles.iconButtonStyle}
        leftIcon={<Ionicons name="logo-apple" size={18} color={COLORS.TEXT_COLOR_PRIMARY} />}
        textProps={{ style: { color: COLORS.TEXT_COLOR_PRIMARY }, size: 3 }}>
        Sign up with Apple
      </Button>
      <View style={styles.loginTextStyle}>
        <Text size={3} color={COLORS.WHITE}>
          Already have an account?{' '}
        </Text>
        <Text size={3} variant="medium" color={COLORS.WHITE} onPress={navigateToSignInScreen}>
          Login
        </Text>
      </View>
    </View>
  );

  const renderLoginOptionButtons = () => (
    <View style={styles.loginButtonsContainer}>
      <Text size={3} numberOfLines={0} color={COLORS.WHITE}>
          If your organization has an account with GoFloaters, then proceed to login / signup.
      </Text>
      <Button
        onPress={() => setCurrentView('LOGIN')}
        variant="small"
        style={styles.buttonStyle}
        iconButtonStyle={styles.iconButtonStyle}
        rightIcon={<Ionicons name="enter" size={16} color={COLORS.TEXT_COLOR_PRIMARY} />}
        textProps={{ style: { color: COLORS.TEXT_COLOR_PRIMARY }, size: 3 }}>
        Login / Signup
      </Button>
      <Text size={3} numberOfLines={0} color={COLORS.WHITE}>
          If you are booking for yourself, then use GoFloaters website.
      </Text>
      <Button
        onPress={() => Linking.openURL("https://gofloaters.com")}
        variant="small"
        style={styles.buttonStyle}
        iconButtonStyle={styles.iconButtonStyle}
        rightIcon={<Ionicons name="open" size={18} color={COLORS.TEXT_COLOR_PRIMARY} />}
        textProps={{ style: { color: COLORS.TEXT_COLOR_PRIMARY }, size: 3 }}>
        Open Website
      </Button>
    </View>
  );

  return (
    <View style={styles.container}>
      {currentView === 'LOGIN_OPTIONS' ? (
        <View style={styles.container}>
          <View style={styles.container}>{renderImage()}</View>
          {renderLoginOptionButtons()}
        </View>
      ) : null
      }
      {currentView === 'LOGIN' ? (
        <View style={styles.container}>
          <View style={styles.container}>{renderImage()}</View>
          {renderLoginActionButtons()}
        </View>
      ) : 
        null
      }
      {currentView === 'SIGN_UP' ? (
        <SignupDetails email={email} userName={userName} onSignupPress={onSignupPress} />
      ) : 
        null
      }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  image: {
    width,
    height: 300,
    resizeMode: 'cover',
  },
  logoStyle: {
    width: '100%',
    height: 80,
    marginVertical: 48,
  },
  actioncontainer: {
    width: '60%',
    alignSelf: 'center',
    marginTop: 24,
  },
  iconContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingHorizontal: 16,
  },
  iconButtonStyle: {
    width: '90%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconStyle: {
    paddingVertical: 10,
    paddingHorizontal: 12,
  },
  loginButtonsContainer: {
    backgroundColor: COLORS.LOGIN_BG_COLOR,
    paddingVertical: 32,
    paddingHorizontal: 80,
    rowGap: 16,
  },
  buttonStyle: {
    backgroundColor: COLORS.WHITE,
  },
  loginTextStyle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  loginImageStyle: {
    flex: 1,
    resizeMode: 'cover',
    // justifyContent: 'flex-end',
    // alignItems: 'center',
    position: 'relative',
  },
  loginBannerTextStyle: {
    textAlign: 'center',
    rowGap: 8,
    position: 'absolute',
    left: 48,
    right: 48,
  },
});

const mapStateToProps = state => {
  // console.log({ stateLogin: state });
  return {
    metadata: state.metadata.data,
  };
};

export default connect(mapStateToProps)(Login);
