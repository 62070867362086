import React from 'react';
import { View, StyleSheet, Dimensions, Pressable } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { COLORS } from '../constants/colors';
import Text from './Text';
import { ROUTES } from '../constants/routes';
import { MAXDEVICEWIDTH } from '../constants/common';
import { connect } from 'react-redux';
import { getIsB2CUser } from '../selectors/organization';

const { width } = Dimensions.get('window');
const TOOL_ITEM_SIZE = (width >MAXDEVICEWIDTH?MAXDEVICEWIDTH:width)  * 0.4;
const ICON_SIZE = 40;

const EmptyHome = ({ navigation,isB2CUser }) => {
  const onSearchScreenPress = () => {
    navigation.navigate(ROUTES.SEARCH.name);
  };

  const onLocateTeamPress = () => {
    navigation.navigate(ROUTES.TEAM_VIEW.name);
  };
  return (
    <View style={styles.container}>
      <Text variant="semiBold">Welcome to GoFloaters WorqFlexi.</Text>
      {isB2CUser?(<Text variant="semiBold" numberOfLines={0}>Once you start booking, your favorite spaces and your active bookings will appear here. Get started by searching for your first space.</Text>):(<Text variant="semiBold" numberOfLines={0}>Once you start booking, your favorite spaces and your active bookings will appear here. Get started by searching for your first space or locate where you team is and join them.</Text>)}
      {/* <Text variant="semiBold">You can search for spaces to book or locate where you team is and join them.</Text> */}
      <View style={{ flexDirection: 'row' }}>
        <View style={styles.itemContainer}>
          <Pressable style={styles.item} onPress={onSearchScreenPress}>
            <View style={{ height: 48, marginBottom: 4 }}>
              <FontAwesome name="search" size={ICON_SIZE} color={COLORS.PRIMARY_COLOR} />
            </View>
            <Text size={1} variant="semiBold">
              Search Spaces
            </Text>
          </Pressable>
        </View>
        {isB2CUser?null:(
        <View style={styles.itemContainer}>
          <Pressable style={styles.item} onPress={onLocateTeamPress}>
            <View style={{ height: 48, marginBottom: 4 }}>
              <FontAwesome name="users" size={ICON_SIZE} color={COLORS.PRIMARY_COLOR} />
            </View>
            <Text size={1} variant="semiBold">
              Locate Team
            </Text>
          </Pressable>
        </View>)}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.WHITE,
    paddingHorizontal: 16,
    paddingTop: 16,
    rowGap: 24,
  },
  itemContainer: {
    width: '50%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  item: {
    width: TOOL_ITEM_SIZE,
    height: TOOL_ITEM_SIZE,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: COLORS.CATEGORY_BORDER,
    backgroundColor: COLORS.CATEGORY_BG,
    textAlign: 'center',
    padding: 8,
    paddingTop: 24,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

// export default EmptyHome;

const mapStateToProps = state =>
  ({
    isB2CUser: getIsB2CUser(state),
  });

export default connect(mapStateToProps)(EmptyHome);
