import React, { useState } from "react";
import {
    View,
    Text,
    TextInput,
    TouchableOpacity,
    StyleSheet,
    ActivityIndicator,
} from "react-native";
import { applyDiscountCode } from "../../api/common";
import Button from "../Button";

const ApplyDiscount = (props) => {
    const [discountCode, setDiscountCode] = useState("");
    const [validating, setValidating] = useState(false);
    const [couponDiscount, setCouponDiscount] = useState(null);

    const handleValidate = async () => {
        // Check if discountCode length is less than 3
        if (discountCode.length <= 3) {
            console.error("Discount code length must be less than 3 characters.");
            return;
        }

        setValidating(true);

        const payload = {
            discountCode: discountCode,
            custId: props.user.uid, 
        };

        try {
            const couponDetails = await applyDiscountCode({ payload }); // Call your applyDiscount function

            setCouponDiscount(couponDetails.data);

            if (couponDetails.data.status === "success") {
                props.buttonClick(
                    couponDetails.data.couponDetails.discountPercentage,
                    discountCode
                );
            } else {
                props.buttonClick(0, discountCode);
            }
        } catch (error) {
            console.error("Error validating discount code:", error);
        } finally {
            setValidating(false);
        }
    };

    return (
        <View style={styles.container}>
            <View style={styles.row}>
                <TextInput
                    style={styles.inputRow}
                    placeholder="Discount Code"
                    value={discountCode}
                    onChangeText={setDiscountCode}
                    autoCapitalize="none"
                    keyboardType="default"
                    
                />
                <Button
                bordered
                    mode="contained"
                    onPress={handleValidate}
                    disabled={discountCode.length < 3 || validating}
                    loading={validating}
                    style={styles.buttonText}
                    textProps={{ variant: 'bold', size: 3 }}
                >
                    Apply Now
                </Button>
            </View>
            <View style={styles.messageContainer}>
                {couponDiscount &&
                    couponDiscount.status === "success" &&
                    couponDiscount.couponDetails &&
                    couponDiscount.couponDetails.spaceTypes &&
                    couponDiscount.couponDetails.spaceTypes.includes(props.spaceType) ? (
                    <Text style={styles.successMessage}>
                        {parseFloat(
                            couponDiscount.couponDetails.discountPercentage
                        ).toFixed(1)}
                        % Discount Applied
                    </Text>
                ) : null}
                {couponDiscount && couponDiscount.status !== "success" ? (
                    <Text style={styles.errorMessage}>Enter valid discount code</Text>
                ) : null}
            </View>
        </View>

    );
};

const styles = StyleSheet.create({
    container: {
        marginTop: 18,
        padding: 6,
    },
    row: {
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 5,
    },
    inputRow: {
        flex: 1, // Takes up the remaining space
        height: 40,
        borderColor: "#ccc",
        borderWidth: 1,
        borderRadius: 8,
        paddingHorizontal: 18,
        backgroundColor: "#ffffff",
        marginRight: 10, // Adds spacing between TextInput and Button
    },
    buttonText: {
        paddingHorizontal: 20,
        paddingVertical: 10,
    },
    disabledButton: {
        backgroundColor: "#cccccc",
    },
    messageContainer: {
        marginTop: 0,
    },
    successMessage: {
        color: "#16cc0d",
        fontSize: 14,
        marginTop: 8,
    },
    errorMessage: {
        color: "red",
        fontSize: 14,
        marginTop: 2,
    },
});


export default ApplyDiscount;
