import { View, StyleSheet, FlatList, Pressable, Dimensions } from 'react-native';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _concat from 'lodash/concat';
import { connect } from 'react-redux';
import { forwardRef, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { Popable, usePopable } from 'react-native-popable';

import { FILTER_TYPES, QUICK_FILTERS, SPACE_TYPE_VALUES } from '../constants/common';
import Text from './Text';
import { COLORS } from '../constants/colors';
import { SEAT_FILTER, getSupportedFilters } from '../utils/common';
import SeatSelector from './SeatSelector';
import Button from './Button';

const CARET_SIDE_SIZE = 10;
const BORDER_RADIUS = 2;

const { width, height } = Dimensions.get('window');

const SeatFilter = forwardRef(({ filterStyle, item, onSeatSelect }, ref) => (
  <Pressable style={filterStyle} onPress={onSeatSelect} ref={ref}>
    <Text size={4}>{_get(item, 'filterName')}</Text>
  </Pressable>
));

const QuickFilters = ({ style, onSelectFilter, selectedFilter, spaceType, metadata, organization }) => {
  const [seatFilter, setSeatFilter] = useState(() =>
    spaceType === SPACE_TYPE_VALUES.OFFICE_SPACES ? SEAT_FILTER : []
  );
  const [showSeatSelection, setShowSeatSelection] = useState(false);
  const [seatSelectionPosition, setSelctionPosition] = useState({ left: 0, top: 0 });
  const seatRef = useRef(null);

  useEffect(() => {
    setSeatFilter(spaceType === SPACE_TYPE_VALUES.OFFICE_SPACES ? SEAT_FILTER : []);
  }, [spaceType]);
  const filters = useMemo(
    () => getSupportedFilters(spaceType, metadata, organization),
    [spaceType, metadata, organization]
  );

  const toggleSeatSelection = () => {
    if (_get(selectedFilter, 'filterKey') !== _get(seatFilter, 'filterKey')) {
      seatRef?.current?.measure((_x, _y, _width, _height, pageX, pageY) => {
        setSelctionPosition({ left: pageX, top: pageY });
      });
      setShowSeatSelection(true);
    } else {
      onSelectFilter({});
    }
  };

  const updateSeats = seats => {
    if (seats > 0) {
      setSeatFilter({ ...seatFilter, seatRangeFrom: seats });
    }
  };
  const onSeatSelectionDonePress = () => {
    onSelectFilter(seatFilter);
    setShowSeatSelection(false);
  };

  const renderItem = ({ item }) => {
    const filterStyle = [styles.filterContainer];
    if (_get(item, 'filterKey') === _get(selectedFilter, 'filterKey')) filterStyle.push(styles.selectedFilter);
    const onFilterPress = () => {
      if (_isEqual(_get(item, 'filterKey'), _get(selectedFilter, 'filterKey'))) onSelectFilter({});
      else onSelectFilter(item);
    };

    if (_get(item, 'type') !== FILTER_TYPES.POPOVER) {
      return (
        <Pressable style={filterStyle} onPress={onFilterPress}>
          <Text size={4}>{_get(item, 'filterName')}</Text>
        </Pressable>
      );
    }
    return (
      <SeatFilter
        ref={seatRef}
        value={_get(item, 'seatRangeFrom')}
        onSeatSelect={toggleSeatSelection}
        item={item}
        filterStyle={filterStyle}
      />
    );
  };

  return (
    <>
      <View style={{ flexDirection: 'row', zIndex: -1, paddingVertical: 16 }}>
        <FlatList
          horizontal
          showsHorizontalScrollIndicator={false}
          data={_concat(filters, seatFilter)}
          renderItem={renderItem}
          keyExtractor={item => _get(item, 'filterKey')}
          removeClippedSubviews={false}
          style={[style]}
        />
      </View>
      {showSeatSelection ? (
        <Pressable
          onPress={() => setShowSeatSelection(false)}
          style={{
            position: 'absolute',
            backgroundColor: 'rgba(0,0,0,0.7)',
            width,
            height,
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            zIndex: 999999,
          }}>
          <View
            style={{
              left: width - 248 - 16,
              top: seatSelectionPosition.top - 60 - CARET_SIDE_SIZE,
            }}>
            <View style={{}}>
              <View
                style={{
                  width: 248,
                  height: 60,
                  backgroundColor: 'white',
                  borderRadius: 4,
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}>
                <SeatSelector
                  setSeats={updateSeats}
                  seats={_get(seatFilter, 'seatRangeFrom')}
                  labelStyle={{ color: COLORS.TEXT_COLOR_PRIMARY }}
                />
                <Button
                  onPress={onSeatSelectionDonePress}
                  variant="small"
                  textProps={{ size: 4 }}
                  style={{ paddingHorizontal: 8, marginHorizontal: 16 }}>
                  Done
                </Button>
              </View>
              <View
                style={{
                  width: CARET_SIDE_SIZE,
                  height: CARET_SIDE_SIZE,
                  backgroundColor: 'white',
                  left: 248 - 16,
                  transform: [{ rotate: '45deg' }],
                  borderRadius: BORDER_RADIUS,
                  // marginBottom: (CARET_SIDE_SIZE / 2 + BORDER_RADIUS / 2) * -1,
                  marginTop: (CARET_SIDE_SIZE / 2 + BORDER_RADIUS / 2) * -1,
                }}
              />
            </View>
          </View>
        </Pressable>
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  filterContainer: {
    padding: 8,
    backgroundColor: COLORS.PROFILE_CARD_BACKGROUND,
    flexDirection: 'row',
    marginRight: 10,
    borderRadius: 4,
    borderWidth: 2,
    borderColor: COLORS.PROFILE_CARD_BACKGROUND,
  },
  selectedFilter: {
    borderWidth: 2,
    borderColor: COLORS.PRIMARY_COLOR,
  },
});

export default QuickFilters;
