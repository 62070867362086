import React, { useCallback, useEffect, useMemo } from 'react';
import { Alert, Dimensions, Linking, Platform, Image } from 'react-native';
// import { View, Text, TouchableOpacity } from 'react-native';
import notifee, { EventType } from '@notifee/react-native';

import messaging from '@react-native-firebase/messaging';
import { getMessaging, getToken } from 'firebase/messaging';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import { MaterialIcons, FontAwesome, Feather, FontAwesome5 } from '@expo/vector-icons';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
// import FIcon from 'react-native-vector-icons/FontAwesome';

import { useNavigation } from '@react-navigation/native';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { ROUTES } from '../constants/routes';
import HeaderWithoutBack from '../components/HeaderWithoutBack';
import Search from '../screens/search';
// import Discover from '../screens/discover';
import Bookings from '../screens/bookings';
// import Account from '../screens/account';
// import GradientButton from '../components/GradientButton';
// import Button from '../components/Button';
// import HeaderWithoutBack from '../components/HeaderWithoutBack';
// import BookingsNavigator from './BookingsNavigator';
import Home from '../screens/home/Home';
import Profile from '../screens/profile';
import TeamView from '../screens/teamView';
import { COLORS } from '../constants/colors';
import ColorfulTabBar from '../components/Tabbar';
import DeeplinkWrapper from '../wrappers/DeeplinkWrapper';
import { getScreenAndAdditionalPropsFromRemoteMessage, subscribeUserToTopic } from '../utils/notifications';
import { updatePushNotificationToken } from '../utils/firebaseUtils';
import { initSalesIq } from '../utils/salesIq';
import SalesIQ from '../components/SalesIq';
import { messaging as messagingWeb } from '../../firebaseConfig';
import { getNotifications } from '../utils/firebaseNotification';
import { getScreenAndParamsFromUrl } from '../utils/common';
import Tools from '../screens/tools';
import { IMAGES } from '../assets/images';
import { getIsB2CUser } from '../selectors/organization';

const { width } = Dimensions.get('window');
// import Bookings from '../screens/bookings';

// const TABS_INFO = {
//   [ROUTES.HOME.name]: {
//     name: 'home',
//     icon: 'search',
//     inActive: '#DFDEDE',
//     // active: '#41AFCA',
//     active: '#46B3CE',
//   },
//   [ROUTES.DISCOVER.name]: {
//     name: 'discover',
//     icon: 'search',
//     inActive: '#DFDEDE',
//     active: '#41AFCA',
//   },
//   [ROUTES.BOOKINGS.name]: {
//     name: 'bell',
//     icon: 'bell',
//     inActive: '#DFDEDE',
//     active: '#41AFCA',
//   },
//   [ROUTES.ACCOUNT.name]: {
//     name: 'account',
//     icon: 'user',
//     inActive: '#DFDEDE',
//     active: '#41AFCA',
//   },
// };

// function Tabs({ state, descriptors, navigation }) {
//   return (
//     <View
//       style={{
//         flexDirection: 'row',
//         height: 64,
//         backgroundColor: 'white',
//       }}>
//       {state.routes.map((route, index) => {
//         const { options } = descriptors[route.key];
//         const label =
//           options.tabBarLabel !== undefined
//             ? options.tabBarLabel
//             : options.title !== undefined
//             ? options.title
//             : route.name;

//         const isFocused = state.index === index;

//         const onPress = () => {
//           const event = navigation.emit({
//             type: 'tabPress',
//             target: route.key,
//             canPreventDefault: true,
//           });

//           if (!isFocused && !event.defaultPrevented) {
//             // The `merge: true` option makes sure that the params inside the tab screen are preserved
//             navigation.navigate({ name: route.name, merge: true });
//           }
//         };

//         const Component = isFocused ? GradientButton : Button;

//         return (
//           <View key={TABS_INFO[label].name} style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
//             <Component
//               onPress={onPress}
//               style={{
//                 paddingHorizontal: 16,
//                 paddingVertical: 4,
//                 backgroundColor: 'white',
//               }}
//               colors={['rgba(109, 213, 237, 0.2)', 'rgba(39, 152, 181, 0.2)']}>
//               <FIcon
//                 name={TABS_INFO[label].icon}
//                 size={24}
//                 color={isFocused ? TABS_INFO[label].active : TABS_INFO[label].inActive}></FIcon>
//             </Component>
//           </View>
//         );
//       })}
//     </View>
//   );
// }

const iconSize = 18;
const tabBarHeight = Platform.OS === 'ios' ? 80 : 60;
function MainTabNavigator({ user, isB2CUser }) {
  const navigation = useNavigation();
  const navigateUser = useCallback((data = {}) => {
    const { screen, navProps = {} } = getScreenAndAdditionalPropsFromRemoteMessage(data);
    if (screen) navigation.navigate(screen, navProps);
  });
  const uid = useMemo(() => _get(user, 'uid'), [user]);
  const organizationId = useMemo(() => _get(user, 'organizationId'), [user]);

  const isB2C = useMemo(() => isB2CUser);

  const b2bTabs = [
    {
      name: ROUTES.HOME.name,
      component: Home,
      icon: ({ focused }) => (
        <MaterialIcons name="home-filled" size={iconSize} color={focused ? COLORS.PRIMARY_COLOR : COLORS.GRAY} />
      ),
      tabBarLabel: ROUTES.HOME.label,
      headerShown: false,
    },
    {
      name: ROUTES.TEAM_VIEW.name,
      component: TeamView,
      icon: ({ focused }) => (
        <FontAwesome name="users" size={iconSize} color={focused ? COLORS.PRIMARY_COLOR : COLORS.GRAY} />
      ),
      tabBarLabel: ROUTES.TEAM_VIEW.label,
      headerShown: false,
    },
    {
      name: ROUTES.SEARCH.name,
      component: Search,
      icon: ({ focused }) => (
        <FontAwesome name="search" size={iconSize} color={focused ? COLORS.PRIMARY_COLOR : COLORS.GRAY} />
      ),
      tabBarLabel: ROUTES.SEARCH.label,
      headerShown: false,
    },
    {
      name: ROUTES.BOOKINGS.name,
      component: Bookings,
      icon: ({ focused }) => (
        <Feather name="calendar" size={iconSize} color={focused ? COLORS.PRIMARY_COLOR : COLORS.GRAY} />
      ),
      tabBarLabel: ROUTES.BOOKINGS.label,
      header: () => <HeaderWithoutBack title="My Bookings" />,
    },
    {
      name: ROUTES.ENGAGE.name,
      component: Tools, // Make sure to import this if not done already
      icon: ({ focused }) => (
        <Image source={focused ? IMAGES.ENGAGE_SELECTED : IMAGES.ENGAGE} style={{ width: 24, height: 24 }} />
      ),
      tabBarLabel: ROUTES.ENGAGE.label,
      header: () => <HeaderWithoutBack title="Engage" />,
    },
  ];


  const b2cTabs = [
    {
      name: ROUTES.HOME.name,
      component: Home,
      icon: ({ focused }) => (
        <MaterialIcons name="home-filled" size={iconSize} color={focused ? COLORS.PRIMARY_COLOR : COLORS.GRAY} />
      ),
      tabBarLabel: ROUTES.HOME.label,
      headerShown: false,
    },
    {
      name: ROUTES.SEARCH.name,
      component: Search,
      icon: ({ focused }) => (
        <FontAwesome name="search" size={iconSize} color={focused ? COLORS.PRIMARY_COLOR : COLORS.GRAY} />
      ),
      tabBarLabel: ROUTES.SEARCH.label,
      headerShown: false,
    },
    {
      name: ROUTES.BOOKINGS.name,
      component: Bookings,
      icon: ({ focused }) => (
        <Feather name="calendar" size={iconSize} color={focused ? COLORS.PRIMARY_COLOR : COLORS.GRAY} />
      ),
      tabBarLabel: ROUTES.BOOKINGS.label,
      header: () => <HeaderWithoutBack title="My Bookings" />,
    },
  ];


  useEffect(() => {
    const unsubscribe = notifee.onForegroundEvent(({ type, detail }) => {
      switch (type) {
        case EventType.PRESS:
          navigateUser(_get(detail, 'notification'));
          break;
        default:
          break;
      }
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      if (uid) {
        getNotifications(uid, navigateUser);
      }
    } else {
      getNotifications(uid, navigateUser);
    }
    return () => { };
  }, [uid]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      subscribeUserToTopic(organizationId);
    }
  }, [organizationId]);

  const handleLinkUrl = url => {
    // console.log({ url });
    if (url) {
      const { screen, params } = getScreenAndParamsFromUrl(url);
      if (screen) {
        const paramsToPass = _isEmpty(params) ? {} : params;
        navigation.navigate(screen, paramsToPass);
      }
    }
  };

  useEffect(() => {
    Linking.getInitialURL()
      .then(url => {
        handleLinkUrl(url);
      })
      .catch(error => console.log({ initialUrlError: error }));

    Linking.addEventListener('url', event => {
      handleLinkUrl(_get(event, 'url'));
    });

    return () => {
      Linking.removeEventListener('url', handleLinkUrl);
    };
  }, []);

  useEffect(() => {
    if (user) initSalesIq(user);
  }, [user]);

  const isSupportedWidth = useMediaQuery({
    maxDeviceWidth: 576,
  });

  const Tab = useMemo(
    () => (isSupportedWidth || Platform.OS !== 'web' ? createBottomTabNavigator() : createMaterialTopTabNavigator()),
    [isSupportedWidth]
  );

  return (
    <>
      <DeeplinkWrapper>
        <Tab.Navigator
          initialRouteName={isB2C ? ROUTES.SEARCH.name : ROUTES.HOME.name}
          tabBar={props => <ColorfulTabBar {...props} height={tabBarHeight} maxWidth={width} />}
          screenOptions={{ tabBarShowLabel: false, tabBarActiveTintColor: COLORS.PRIMARY_COLOR }}
        >
          {(isB2C ? b2cTabs : b2bTabs).map(tab => (
            <Tab.Screen
              key={tab.name}
              name={tab.name}
              component={tab.component}
              options={{
                tabBarIcon: tab.icon,
                headerShown: tab.headerShown,
                tabBarLabel: tab.tabBarLabel,
                header: tab.header,
              }}
            />
          ))}
        </Tab.Navigator>
      </DeeplinkWrapper>
      <SalesIQ user={user} />
    </>
  );
}
const mapStateToProps = state => ({
  isB2CUser: getIsB2CUser(state)
});

export default connect(mapStateToProps)(MainTabNavigator)
