import { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useToast } from 'react-native-toast-notifications';
import { useNavigation } from '@react-navigation/native';
import { connect } from 'react-redux';
import { ref, onValue } from 'firebase/database';
import Loader from '../../components/Loader';
import { BookingsCard } from '../../components/cards';
import { database } from '../../../firebaseConfig';
import Header from '../../components/Header';
import { COLORS } from '../../constants/colors';
import { ROUTES } from '../../constants/routes';


const BookingDetails = ({ route, user, organization }) => {
  const { bookingkey } = route.params;
  const [loading, setLoading] = useState(false);
  const [booking, setBooking] = useState({});
  const toast = useToast();
  const navigation = useNavigation();
  useEffect(() => {
    setLoading(true);
    const bookingRef = ref(database, `bookings/${bookingkey}`);
    onValue(bookingRef, snapshot => {
      if (snapshot.exists()) {
        // console.log({ bookingRefValue: snapshot.val(), bookingRef });
        // console.log(snapshot.val());
        setBooking(snapshot.val());
      } else {
        setBooking({});
      }
      setLoading(false);
    });
  }, [bookingkey]);

  if (loading) {
    return <Loader />;
  }

  const onBookAgain = spaceId => {
    // toast.show('Book again? 2 ' + spaceId);
    navigation.navigate(ROUTES.SPACE_BOOKINGS.name, { spaceId });
  };

  return (
    <>
      <Header title="Booking Details" />
      <View style={styles.container}>
        <BookingsCard bookingInfo={booking} user={user} organization={organization} onBookAgainPress={onBookAgain} />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.WHITE,
    padding: 16,
  },
});

const mapStateToProps = state => ({ user: state.user.user, organization: state.user.organization });

export default connect(mapStateToProps)(BookingDetails);
