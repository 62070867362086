import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { ActivityIndicator, Dimensions, ImageBackground, ScrollView, StyleSheet, View } from 'react-native';
import _get from 'lodash/get';
import _size from 'lodash/size';

import { Toast, useToast } from 'react-native-toast-notifications';
import Text from '../../components/Text';
import { COLORS } from '../../constants/colors';
import { SPACING, MAXDEVICEWIDTH } from '../../constants/common';
import {
  addEventDetailsToThePayload,
  getMinSeats,
  getSpaceAvailabilityPayload,
  getSpaceAvailabilityPayloadForMeetingSpaces,
  // isInstantlyBookableAwfis,
  // isInstantlyBookableWeWork,
  // isInstantlyBookableCowrks,
  isOfficeSpace,
  calculateBookingAmounts,
} from '../../utils/common';
import Button from '../../components/Button';
import Header from '../../components/Header';
import { cancelBooking, checkBookingAvailability, createBookingB2B, createBookingB2C, createRazorpayOrder, getSpaceDetails, redeemToConfrimBooking, sendUserOtp, updatePaymentStatus } from '../../api/common';
import DeskBooking from './components/DeskBooking';
import MeetingRoomBooking from './components/MeetingRoomBooking';
import { ROUTES } from '../../constants/routes';
import Carousel from '../../components/carousel/CarouselFlatlist';
import Loader from '../../components/Loader';
import BookingConfirmation from '../../components/bookingConfirmation/BookingConfirmation';
import { auth } from '../../../firebaseConfig';
import VerifyBookingOTP from '../../components/verifyBookingOTP';
import RazorpayCheckout from 'react-native-razorpay';
import { getIsB2CUser } from '../../selectors/organization';
import ApplyDiscount from '../../components/applydiscount/ApplyDiscount';

const { width } = Dimensions.get('window');
const IMAGE_WIDTH = (width > MAXDEVICEWIDTH ? MAXDEVICEWIDTH : width) - 2 * SPACING;

const SpaceBooking = ({ route, user, navigation, organization, isB2CUser }) => {
  const { spaceId = '-N-CGjeH1xuqomuDPtPV', timeStamp, eventId } = route.params;
  const uid = _get(user, 'uid');
  const [loading, setLoading] = useState(true);
  const [spaceDetails, setSpaceDetails] = useState(null);
  const [booking, setBooking] = useState(false);
  const [bookingDetails, setBookingDetails] = useState(null);
  const [noOfSeats, setNoOfSeats] = useState(getMinSeats(spaceDetails));
  const [showOtpView, setShowOtpView] = useState(false);
  const [otp, setOtp] = useState();
  const toast = useToast();
  const [discountAmount, setdiscountAmount] = useState(0)
  const [discountCode, setDiscountCode] = useState(null)

  const deskBookingRef = useRef(null);
  const meetingRoomBookingRef = useRef(null);

  useEffect(() => {
    async function fetchSpaceDetails() {
      setLoading(true);
      const response = await getSpaceDetails(spaceId, uid);
      setSpaceDetails(response);
      setNoOfSeats(getMinSeats(response));
      setLoading(false);
    }
    if (spaceId && uid && auth?.currentUser?.getIdToken()) fetchSpaceDetails();
  }, [spaceId, uid, auth?.currentUser]);

  const { originalName, photos, priceperday, facilitiesList, address, exclusiveOffer, gofloatersSpaceName } = useMemo(
    () => ({
      originalName: _get(spaceDetails, 'originalName', ''),
      photos: _get(spaceDetails, 'photos', []),
      priceperday: _get(spaceDetails, 'priceperday', 0),
      facilitiesList: _get(spaceDetails, 'facilitiesList', []),
      address: _get(spaceDetails, 'address', {}),
      exclusiveOffer: _get(spaceDetails, 'exclusiveOffer', ''),
      gofloatersSpaceName: _get(spaceDetails, 'gofloatersSpaceName', ''),
    }),
    [spaceDetails]
  );

  const onBookNowPress = async () => {
    if (!_get(spaceDetails, 'isSpaceApprovedForOrg') && !_get(user, 'isOrganizationAdmin')) {
      toast.show("Kindly contact your organization's admin or facility manager to book this space");
      return;
    }
    if (_get(organization, 'config.validateOTPForBooking')) {
      if (isOfficeSpace(spaceDetails)) {
        const bookingPayload = deskBookingRef?.current.getBookingPayload();
        if (!bookingPayload) return;
      } else {
        const bookingPayload = meetingRoomBookingRef?.current.getBookingPayload();
        if (!bookingPayload) return;
      }
      try {
        setBooking(true);
        const response = await sendUserOtp(uid);
        setShowOtpView(true);
        setOtp(_get(response, 'data.otpHash'));
      } catch (error) {
        toast.show('Error sending otp. Please try again later.');
      } finally {
        setBooking(false);
      }
    } else {
      bookSpace();
    }
  };


  const bookSpace = async () => {
    setBooking(true);
    try {
      // const isB2CUser = getIsB2CUser()

      const getBookingPayloads = (isOfficeSpace, deskBookingRef, meetingRoomBookingRef) => {
        return isOfficeSpace
          ? deskBookingRef?.current?.getBookingPayload()
          : meetingRoomBookingRef?.current?.getBookingPayload();
      };

      const bookingPayload = getBookingPayloads(
        isOfficeSpace(spaceDetails),
        deskBookingRef,
        meetingRoomBookingRef
      );

      const spaceAvailabilityPayload = isOfficeSpace(bookingPayload)
        ? getSpaceAvailabilityPayloadForMeetingSpaces(spaceId, bookingPayload, isB2CUser)
        : getSpaceAvailabilityPayload(spaceId, bookingPayload, isB2CUser);

      const availabilityResponse = await checkBookingAvailability(spaceAvailabilityPayload);
      if (availabilityResponse) {
        bookingPayload.discountCode = discountCode
        try {
          const bookingResponse = isB2CUser
            ? await createBookingB2C(addEventDetailsToThePayload(bookingPayload))
            : await createBookingB2B(addEventDetailsToThePayload(bookingPayload, eventId));

          // const { booking, proceedToPayment } = isB2CUser ? bookingResponse : bookingResponse[0];

          

          if (isB2CUser) {
            const { booking, proceedToPayment } = bookingResponse;
            if (proceedToPayment) {
              const price = calculateBookingAmounts(user, booking, [booking], discountAmount);
              const razorpayResponse = await createRazorpayOrder(_get(booking, "bookingkey"));
              // console.log({ razorpayResponse });
              const razorpayPaymentDetails = await openRazorpayForPayment(
                _get(razorpayResponse, "orderId"),
                booking,
                price
              );
              // console.log("Razorpay Payment Details:", razorpayPaymentDetails);
            } else {
              let bookingDetailsArray = [];
              bookingDetailsArray.push({"booking":bookingResponse});
              setBookingDetails(bookingDetailsArray);
            }
          } else {
            setBookingDetails(bookingResponse);
          }
          // if (proceedToPayment) {
          //   if (isB2CUser) {
              
          //   } else {
          //     console.error("Unexpected proceedToPayment for B2B user.");
          //   }
          // } else {
          //   if (!isB2CUser) {
          //     const response = await redeemToConfrimBooking(booking, user);
          //     if ((response)) {
          //       setBookingDetails(_get(response, "booking"));
          //       // console.log("Credits redeemed successfully.");
          //       alert("Credits redeemed successfully.");
          //     }
          //   } else {
          //     console.error("Unexpected proceedToPayment false for B2C user.");
          //   }
          // }
          // setBookingDetails(booking);
        }
        catch (error) {
          console.error("Error handling booking flow:", error);
        }
      }
    } catch (error) {
      console.error('Booking error:', error);
      toast.show(
        error?.response?.data?.error?.message ?? 'Error processing your request. Please try again.'
      );
    } finally {
      setBooking(false);
    }
  };


  const openRazorpayForPayment = useCallback(
    async (orderId, booking, price) => {
      try {
        const totalPayableAmount = _get(price, "totalPrice");
        // console.log({ price, totalPayableAmount });
        const razorpayKey = "rzp_live_L4jnEFvzps4krf";
        // const razorpayKey = "rzp_test_87k0WW6Nk4NGoC";
        const options = {
          key: razorpayKey,
          amount: totalPayableAmount,
          currency: "INR",
          name: "Gofloaters",
          description: "Payment for the space booking",
          image: "https://assets.gofloaters.com/logo.png",
          order_id: orderId,
          prefill: {
            name: _get(user, "displayName"),
            email: _get(user, "email"),
            contact: _get(user, "phoneNumber"),
          },
          notes: {
            address: "Gofloaters",
          },
          theme: {
            color: "#2798B5",
          },
        };

        RazorpayCheckout.open(options)
          .then(async (data) => {
            const updatePaymentResponse = await updatePaymentStatus({
              paymentRefNumber: _get(data, "razorpay_payment_id"),
              uid: _get(user, "uid"),
              spaceType: _get(booking, "spaceType"),
              spaceUsageFee: _get(price, "spaceUsageFee"),
              gstFee: _get(price, "gst"),
              redeemedPoints: _get(user, "floatingpoints"),
              id: _get(booking, "bookingkey"),
            });

            // console.log(updatePaymentResponse)
            // console.log("Payment status updated successfully:", updatePaymentResponse);
            if (updatePaymentResponse) {
              let bookingDetailsArray = [];
              bookingDetailsArray.push({"booking":updatePaymentResponse});
              setBookingDetails(bookingDetailsArray);
              // setBookingDetails(updatePaymentResponse);
              // Toast.show({
              //   type: "success",
              //   text1: "Payment Success",
              //   text2: `Payment updated successfully!`,
              //   position: "top",
              //   visibilityTime: 3000,
              // });
            }
            
          })
          .catch((error) => {
            console.log({ error })
          });
      } catch (error) {
        console.error("Error initiating Razorpay", error);
        Alert.alert("Error", `Error opening payment gateway: ${error.message || error}`);
      }
    },
    [user, booking]
  );

  const handleDiscount = (discountPercentage, discountCode) => {
    if (discountPercentage) {
      setdiscountAmount(Number(discountPercentage))
      setDiscountCode(discountCode)
    } else {
      setdiscountAmount(0)
    }
  }
  useEffect(() => {
    // Here you can perform any side effects that depend on discountAmount
    // For example, you might want to update some state or perform some action
    // based on the value of discountAmount
    // console.log("Discount amount changed:", discountAmount)
  }, [discountAmount])




  if (loading)
    return (
      <View style={[styles.containerPadding, styles.centerView]}>
        <ActivityIndicator />
      </View>
    );

  const renderBookingScreen = () => (
    <View style={styles.container}>
      <Header title={[gofloatersSpaceName, ' - ', originalName]} />
      <ScrollView style={styles.containerPadding} contentContainerStyle={{ paddingBottom: 100 }}>
        <View style={styles.image}>
          <Carousel
            data={photos}
            paginationWidth={IMAGE_WIDTH}
            renderItem={({ item }) => (
              <ImageBackground style={styles.image} source={{ uri: item }} resizeMode="cover" />
            )}
            keyExtractor={item => item}
          />
        </View>

        {isOfficeSpace(spaceDetails) ? (
          <DeskBooking
            timeStamp={timeStamp}
            spaceDetails={spaceDetails}
            ref={deskBookingRef}
            priceDiscount={discountAmount}
            user={user}
            organization={organization}
            disableEdit={!!eventId}
            isB2CUser={isB2CUser}
          />
        ) : (
          <MeetingRoomBooking
            timeStamp={timeStamp}
            spaceDetails={spaceDetails}
            ref={meetingRoomBookingRef}
            priceDiscount={discountAmount}
            user={user}
            organization={organization}
            isB2CUser={isB2CUser}
          />
        )}
        {isB2CUser ? (
          <ApplyDiscount
            spaceType={_get(spaceDetails, "spaceType")}
            user={user}
            buttonClick={handleDiscount}
          />
        ) : (
          null
        )}

      </ScrollView>

      <View style={styles.buttonStyle}>
        <Button textProps={{ variant: 'bold', size: 3 }} onPress={onBookNowPress} loading={booking}>
          Book now
        </Button>
      </View>
      {showOtpView ? <VerifyBookingOTP onVerify={bookSpace} otp={otp} /> : null}
    </View>
  );

  const renderBookingConfirmationScreen = () => (
    <BookingConfirmation navigation={navigation} bookingDetails={bookingDetails} user={user} />
  );

  // console.log({ bookingDetails });

  return !bookingDetails ? renderBookingScreen() : renderBookingConfirmationScreen();

}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.WHITE,
  },
  containerPadding: {
    paddingHorizontal: SPACING,
    paddingTop: SPACING,
  },
  image: {
    width: IMAGE_WIDTH,
    height: (1 / 1.78) * ((width > MAXDEVICEWIDTH ? MAXDEVICEWIDTH : width) - SPACING * 3 - 4),
    resizeMode: 'cover',
    borderRadius: 16,
    overflow: 'hidden',
  },
  paginationDotStyle: {
    borderRadius: 8,
    maxWidth: 8,
    maxHeight: 8,
  },
  baseMarginTop: {
    marginTop: 20,
  },
  subMarginTop: {
    marginTop: 10,
  },
  margin30: {
    marginTop: 30,
  },
  dateTimeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  fullFlex: {
    flex: 1,
  },
  buttonStyle: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    padding: 16,
    backgroundColor: COLORS.WHITE,
    borderTopColor: COLORS.PRIMARY_BORDER_COLOR,
    // borderTopWidth: 1,
    // shadowColor: 'black',
    shadowOffset: { width: 0, height: 3 },
    shadowRadius: 20,
    shadowOpacity: 0.2,
    elevation: 8,
  },
});

const mapStateToProps = state => ({
  user: state.user.user,
  organization: state.user.organization,
  isB2CUser: getIsB2CUser(state)
});

export default connect(mapStateToProps)(SpaceBooking);
